import "./App.css";
var ReactRotatingText = require("react-rotating-text");

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>
          <ReactRotatingText
            color="black"
            typingInterval="100"
            items={[
              "me fr",
              "fr fr",
            ]}
          />
        </h1>
      </header>
    </div>
  );
}

export default App;
